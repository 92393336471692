export function onChange(element) {
	if (
		!['data-read-more', 'data-read-less'].some((attr) =>
			element.hasAttribute(attr)
		)
	)
		return;

	const { parentElement } = element.closest(
		'.c-consent__multiple-consent-items'
	);
	const containerElement = parentElement.querySelector(
		'.c-consent__consent-container'
	);
	const readMoreElement = parentElement.querySelector('[data-read-more]');
	const readLessElement = parentElement.querySelector('[data-read-less]');

	readMoreElement.classList.toggle('js-hide');
	readLessElement.classList.toggle('js-hide');
	containerElement.classList.toggle('collapsed');
}

export function initShowMore() {
	const showMoreElems = document.querySelectorAll(
		`[data-fn="initShowMore"]`
	);
	showMoreElems.forEach((showMoreElem) => {
		showMoreElem.addEventListener('click', (e) => {
			onChange(e.target);
		});
	});
}
